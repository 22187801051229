<script setup>
defineProps({
  withText: {
    type: Boolean,
    default: false,
  },
})
</script>

<template>
  <div class="in-home-end-page-section row-40-20 max">
    <div class="row-80">
      <div class="container">
        <div class="left-section">
          <h2 class="heading-3">Settori diversi, la stessa eccellenza</h2>
          <div class="text-2">
            <p v-if="withText" class="testo">
              DigitXS supporta le diverse tipologie di imprese nelle attività di
              digitalizzazione, automazione dei processi e gestione efficiente
              dell’intero ciclo di vita dei documenti.
            </p>
            <ul class="list">
              <li>Consulenza</li>
              <li>Servizi</li>
              <li>Finanza</li>
              <li>Assicurazioni</li>
              <li>Manifattura</li>
              <li>Retail</li>
              <li>Tecnologia</li>
              <li>Sanità - Pharma</li>
            </ul>
          </div>
        </div>

        <div class="right-section">
          <!--<h2 class="heading-3">
              È il modo in cui si usano le informazioni a fare la differenza.
            </h2>-->
          <p class="text-2 testo">
            Rimani sempre informato su normative, dati e ultime novità del mondo
            della trasformazione digitale.
          </p>
          <div class="end-page-btn">
            <MainButton
              text="Iscriviti alla Newsletter"
              url="https://5b83cd75.sibforms.com/serve/MUIFABE9Y6LINspLSCb1viJLEr4WPo4s-X2lYMa27HcGmN5CQY08iSl6B1nAFB64jNJ_-hopWUnyU4v3RFeDnZJaW6EhXHMKNRDbuUCNM5NMdcpqmfTHwg3nCOjTUlEOLOUwTtzLw450HJ0Xp43lFhw04O4G6rFLRTkXlynlcIe569eYvaSrQ85c1LZxadzBEK5w3vW-cOc09KQx"
              :internal-link="false"
              :is-black="true"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HomeEndPageSection',
}
</script>

<style lang="scss">
@import './style.scss';
</style>
